import './BlogPostCardGrid.scss';

import { BlogPostCard } from '@/components/BlogPostCard';
import { Post } from '@/interfaces/cms';

const className = 'c-BlogPostCardGrid';

export const BlogPostCardGrid = ({ posts }: { posts: Post[] }) => {
  if (posts.length === 0) {
    return null;
  }

  return (
    <div className={className}>
      {posts.map((post) => {
        const link = `/post/${post.slug}`;

        return (
          <BlogPostCard
            key={post.id}
            image={post.mainImage}
            title={post.title}
            summary={post.summary}
            link={link}
          />
        );
      })}
    </div>
  );
};

export default BlogPostCardGrid;
