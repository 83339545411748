import './BlogPostCard.scss';

import { Button } from '@travelwin/core';
import { useTranslation } from 'react-i18next';

import { Post } from '../../interfaces/cms';
import { ImageMedia } from '../ImageMedia';
import { Link, useNavigate } from '../Link';

const className = 'c-BlogPostCard';

type BlogPostCardProps = {
  image: Post['mainImage'];
  title: string;
  summary: string;
  link: string;
};

export const BlogPostCard = ({
  image,
  title,
  summary,
  link,
}: BlogPostCardProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <article className={className}>
      <ImageMedia
        media={image}
        className={`${className}__image`}
        sizes="(min-width: 768px) 33vw, 100vw"
      />
      <Link className={`${className}__link`} to={link}>
        {title}
      </Link>
      <p className={`${className}__summary`}>{summary}</p>
      <Button variant="secondary" fluid onClick={() => navigate(link)}>
        {t('landing.read_more', 'Read More')}
      </Button>
    </article>
  );
};

export default BlogPostCard;
