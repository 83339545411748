import { ComponentPropsWithoutRef } from 'react';

import { Media } from '@/interfaces/cms';
import { getCmsBaseUrl } from '@/utils/ssrUtils';

export type ImageMediaProps = ComponentPropsWithoutRef<'img'> & {
  media: number | Media;
};

export const ImageMedia = ({ media, sizes, ...props }: ImageMediaProps) => {
  // handle unpopulated CMS fields
  if (typeof media === 'number') {
    return null;
  }

  const srcSet = media.sizes
    ? Object.values(media.sizes)
        .filter((size) => Boolean(size.url && size.width))
        .map(
          (size) =>
            `${encodeURI(`${getCmsBaseUrl()}${size.url}`)} ${size.width}w`,
        )
        .join(', ')
    : '';

  return (
    <img
      src={`${getCmsBaseUrl()}${media.url}`}
      srcSet={srcSet}
      sizes={sizes ?? '100vw'}
      alt={media.alt ?? undefined}
      width={media.width ?? undefined}
      height={media.height ?? undefined}
      {...props}
    />
  );
};
